<template>
  <nav
    class="py-6 px-10 flex sm:flex-row flex-col-reverse justify-between items-center bg-transparent absolute left-0 right-0 top-0 w-full"
  >
    <img src="@/assets/images/newLogoSide.png" alt="LOGO" class="h-20" />
    <app-toggle-language />
  </nav>
  <header
    class="bg-primary-1 pt-32 pb-20 md:pt-44 md:pb-36 text-center rounded-lg"
  >
    <h1
      class="w-10/12 md:w-7/12 text-white text-5xl font-bold m-auto mt-12 sm:mt-0"
    >
      {{ i18n(`settings.tabs.${routeName}`) }}
    </h1>
  </header>
  <section class="pt-20 pb-15">
    <div class="container sm:p-12 px-8 relative">
      <loading-spinner
        type="intersecting-circles"
        :duration="1000"
        :size="80"
        color="#000"
        :loading="!record || findLoading"
      />
      <div v-if="record">
        <p v-html="record.value.en" v-if="!isRTL"></p>
        <p v-html="record.value.ar" v-else></p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { setLanguageCode } from '@/locales/i18n'

export default {
  data() {
    return {
      routeName: null
    }
  },
  computed: {
    ...mapGetters({
      record: 'setting/policies/record',
      findLoading: 'setting/policies/findLoading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      signedIn: 'auth/signedIn',
      currentUser: 'auth/currentUser'
    })
  },
  created() {
    const lang = this.$route.params.lang
    this.routeName = this.$route.name
    if (lang === 'ar') {
      this.doChangeLanguage('ar')
    } else if (lang === 'en') {
      this.doChangeLanguage('en')
    } else {
      this.$router.push({ name: this.routeName, params: { lang: 'en' } })
      this.doChangeLanguage('en')
    }
  },
  async mounted() {
    if (!this.signedIn) {
      await this.doSignInAnonymously()
    }
    await this.doFind(this.routeName)
    // console.log(this.record)
  },
  watch: {
    language(newval) {
      this.$router.push({ name: this.routeName, params: { lang: newval } })
      this.doChangeLanguage(newval)
    }
  },
  methods: {
    ...mapActions({
      doFind: 'setting/policies/doFind',
      doSignInAnonymously: 'auth/doSignInAnonymously'
    }),
    doChangeLanguage(language) {
      this.$i18n.locale = language
      setLanguageCode(language)

      if (language === 'ar') {
        document.body.setAttribute('dir', 'rtl')
      } else {
        document.body.setAttribute('dir', 'ltr')
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style>
html {
  background-color: #fff !important;
}
</style>
